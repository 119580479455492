@import url('https://fonts.googleapis.com/css2?family=Anton&family=Black+Han+Sans&family=Bree+Serif&family=Darker+Grotesque:wght@300..900&family=Dhurjati&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Fugaz+One&family=Inter:wght@100..900&family=Modak&family=Monoton&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Play:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rajdhani:wght@300;400;500;600;700&family=Reem+Kufi+Fun:wght@400..700&family=Seymour+One&family=Signika+Negative:wght@300..700&family=Sofadi+One&family=Stylish&family=Teko:wght@300..700&family=Truculenta:opsz,wght@12..72,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Chewy&family=Fjalla+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Bungee+Spice&family=Chango&family=Chewy&family=Contrail+One&family=Doto:wght@100..900&family=Edu+AU+VIC+WA+NT+Arrows:wght@400..700&family=Fjalla+One&family=Jaro:opsz@6..72&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Bungee+Spice&family=Chewy&family=Doto:wght@100..900&family=Fjalla+One&family=Jaro:opsz@6..72&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Bungee+Spice&family=Chango&family=Chewy&family=Contrail+One&family=Doto:wght@100..900&family=Edu+AU+VIC+WA+NT+Arrows:wght@400..700&family=Fjalla+One&family=Jaro:opsz@6..72&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Permanent+Marker&display=swap');


@keyframes shine {
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: -200% center;
  }
}

#marquee {
  font-family: "Contrail One", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #61dafb;
}


.judul-kerupuk {
  font-family: "Chewy", system-ui;
  font-weight: 600;
  font-style: normal;
  background: linear-gradient(to right, rgb(214, 182, 0), rgb(214, 218, 13));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu-kerupuk {
  font-family: "Amatic SC", serif;
  font-weight: 600;
  font-style: normal;
  color: black;
  font-size: 30px;
}


.menu {
  transition: top 0.5s ease-in-out;
}



.icon {
  cursor: pointer;
  max-width: 100%;
  position: fixed;
  bottom: 100px;
  left: 10px;
  z-index: 3;
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.shopping-cart {
  width: 85px;
  height: 85px;
}
.hidden {
  opacity: 0;
}
.show {
  opacity: 1;
}


@keyframes blinkingBorder {
  0% { border-color: orange; }
  50% { border-color: transparent; }
  100% { border-color: orange; }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.particles {
  background-color: #2a2e31;
  height: 100%;
  width: 10%;
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.category-button {
  margin: 10px;
  height: 50px;
  text-align: left;
  font-weight: bold;
  text-transform: none;
  position: relative; /* Untuk pseudo-element */
  border: none;
  background: none;
  text-transform: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}


#translate {
  color: #282c34;
}

.category-button-container {
  display: flex;
  overflow: hidden;
}

.category-button::after {
  content: '';
  position: absolute;
  bottom: -3px; /* Posisi garis bawah */
  left: 50%; /* Mulai dari tengah */
  width: 0; /* Awalnya garis tidak terlihat */
  height: 2px;
  background-color: orange; /* Warna garis */
  transform: translateX(-50%);
  transition: width 0.4s ease-in-out; /* Animasi memanjang */
}

.category-button:hover::after {
  width: 60%; /* Garis penuh saat hover */
}

#title-pusat-kerupuk {
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: gray;
}

.list-menu{
  font-family: "Rajdhani", sans-serif;
  font-weight: 400;
  font-style: normal;
  position: relative;
  top: -13px;
  width: 100%;
}


.welcome {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.pusat_kerupuk {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

#deskripsi_service {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


#check-2 {
  font-family: "Reem Kufi Fun", sans-serif;
  font-style: normal;
}

#deskripsi_kerupuk {
  font-family: "Darker Grotesque", sans-serif;
  font-style: normal;
}

.lingkaran-container {
  position: relative;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  border-top-color: #ff9c00;
  background-color: #fff;
  border-top: solid 2px #ff9c00;
}




.header-bottom {
  transform: skew(-35deg);
  font-family: 'Assistant', sans-serif;
}

.fast-delivery {
  background-color: #e32222;
  color: white;
}

.secured {
  background-color: #ff9c00;
  color: white;
}

.Affordable {
  background-color: #e32222;
  color: white;
}

.button-view:hover {
  background-color: #e32222;
  color: white;
  cursor: pointer;
}

#button_contact:hover {
  background-color: #282c34;
}



.lingkaran {
  transition: transform 0.5s; /* Menerapkan transisi pada transformasi */
}

.lingkaran:hover {
  transform: rotate(5deg); /* Rotasi sedikit saat dihover */
}

.optimasi {
  position: absolute;
  top: 37%;
  left: 45%;
  transform: translate(-50%, -50%);
}


.barang::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5); /* Warna merah dengan opacity 0.5 */
  z-index: 1; /* Pastikan lapisan ini berada di depan gambar */
}


@keyframes countUp {
  from {
    content: "1";
  }
  to {
    content: "500+";
  }
}

@keyframes countUp1000 {
  from {
    content: "1";
  }
  to {
    content: "1000+";
  }
}

.count-up {
  animation: countUp 5s steps(499, end) forwards;
}

.count-up-1000 {
  animation: countUp1000 5s steps(999, end) forwards;
}

.centered {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* Warna hitam dengan tingkat kejernihan 50% (nilai alpha 0.5) */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.title-kerupuk {
  font-family: "Seymour One", sans-serif;
  font-weight: 200;
}

#title-top-artikel {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

#title-artikel {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

#sub-artikel {
  font-family: "Truculenta", sans-serif;
  font-optical-sizing: auto;
  font-variation-settings:
    "wdth" 100;

}

.product-card {
  transition: transform 0.5s ease-in-out;
}

.flipped-card {
  transform: rotateY(180deg);
}

#review {
  margin-top: 30px;
  width: 100px;
  text-align: center;
}